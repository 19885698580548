import request from '@/utils/request'
import { iResponse } from '@/services/types'

export default () =>
  new (class {
    // 新增角色
    addRole(data: any): Promise<iResponse> {
      return request.post('/role', data, {
        baseURL: '/api/v2',
      })
    }

    // 获取角色列表
    getRoleList(params: any): Promise<iResponse> {
      return request.get('/role', {
        params,
        baseURL: '/api/v2',
      })
    }

    // 获取角色
    getRoleById(id: any): Promise<iResponse> {
      return request.get(`/role/${id}`, {
        baseURL: '/api/v2',
      })
    }
    // 编辑角色
    editRole(id: any, data: any): Promise<iResponse> {
      return request.put(`/role/${id}`, data, {
        baseURL: '/api/v2',
      })
    }
    // 删除角色
    deleteRole(id: any): Promise<iResponse> {
      return request.delete(`/role/${id}`, {
        baseURL: '/api/v2',
      })
    }
    // 修改角色状态
    changeRoleStatus(id: any, data: any): Promise<iResponse> {
      return request.put(`/role/${id}/status`, data, {
        baseURL: '/api/v2',
      })
    }

    /**
      GET /api/v2/project_group 获取项目组列表
      POST /api/v2/project_group 新增项目组
      GET /api/v2/project_group/{id} 获取项目组
      PUT /api/v2/project_group/{id} 修改项目组
      DELETE /api/v2/project_group/{id} 删除项目组
    */
    getGroupList(params: any): Promise<iResponse> {
      return request.get('/project_group', {
        params,
        baseURL: '/api/v2',
      })
    }
    addGroup(data: any): Promise<iResponse> {
      return request.post('/project_group', data, {
        baseURL: '/api/v2',
      })
    }
    getGroup(id: any): Promise<iResponse> {
      return request.get(`/project_group/${id}`, {
        baseURL: '/api/v2',
      })
    }
    editGroup(id: any, data: any): Promise<iResponse> {
      return request.put(`/project_group/${id}`, data, {
        baseURL: '/api/v2',
      })
    }
    deleteGroup(id: any): Promise<iResponse> {
      return request.delete(`/project_group/${id}`, {
        baseURL: '/api/v2',
      })
    }

    /**
      GET /api/v2/user/{id} 获取用户
      PUT /api/v2/user/{id} 修改用户
      DELETE /api/v2/user/{id} 删除用户
      PUT /api/v2/user/{id}/status 修改用户状态
     */
    getUserList(params: any): Promise<iResponse> {
      return request.get('/user', {
        params,
        baseURL: '/api/v2',
      })
    }
    addUser(data: any): Promise<iResponse> {
      return request.post('/user', data, {
        baseURL: '/api/v2',
      })
    }
    aeditUser(id: any, data: any): Promise<iResponse> {
      return request.put(`/user/${id}`, data, {
        baseURL: '/api/v2',
      })
    }
    getUser(id: any): Promise<iResponse> {
      return request.get(`/user/${id}`, {
        baseURL: '/api/v2',
      })
    }
    changeUserStatus(id: any, data: any): Promise<iResponse> {
      return request.put(`/user/${id}/status`, data, {
        baseURL: '/api/v2',
      })
    }
    unlock(id: any): Promise<iResponse> {
      return request.put(`/user/${id}/unlock`, {}, {
        baseURL: '/api/v2',
      })
    }
    deleteUser(id: any): Promise<iResponse> {
      return request.delete(`/user/${id}`, {
        baseURL: '/api/v2',
      })
    }

    getSessionExpiry(): Promise<iResponse> {
      return request.get(`/systemmonitor/session_expiry`)
    }
    setSessionExpiry(data: any): Promise<iResponse> {
      return request.post(`/systemmonitor/session_expiry`, data)
    }
    getMfaConfig(): Promise<iResponse> {
      return request.get(`/systemmonitor/enable_mfa`)
    }

    setMfaConfig(data: any): Promise<iResponse> {
      return request.post(`/systemmonitor/enable_mfa`,data)
    }

  })()